var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"mb-4"},[_vm._v("Список промокодов")]),_c('data-table',{attrs:{"url":"discounts/promo-codes","headers":_vm.headers,"item-key":"name","clickable-rows":""},on:{"click:row":_vm.showPromocode},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var promocode = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'promocodes-id', params: { id: promocode.name } }}},[_vm._v(" "+_vm._s(promocode.name)+" ")])]}},{key:"item.isActive",fn:function(ref){
var promocode = ref.item;
return [_vm._v(_vm._s(_vm._f("yesNo")(promocode.isActive)))]}},{key:"item.createdAt",fn:function(ref){
var promocode = ref.item;
return [_vm._v(_vm._s(_vm._f("dateTime")(promocode.createdAt)))]}},{key:"item.expiredAt",fn:function(ref){
var promocode = ref.item;
return [_vm._v(_vm._s(_vm._f("dateTime")(promocode.expiredAt)))]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }